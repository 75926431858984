<template>
  <div>
    <ui-modal ref="Modal" v-bind="modalProps" v-on="$listeners"  >
      <el-form   ref="form" :rules="rules" :model="createTemplate" hide-required-asterisk>
        <el-form-item label="模板标题" prop="template_title" label-width="80px" >
          <el-input  v-model="createTemplate.template_title"  maxlength="32" show-word-limit placeholder="请输入模版标题"> </el-input>
        </el-form-item>
        <el-form-item label="短信正文" prop="template_content" :rules="rules.template_content" label-width="80px">
          <el-select  v-model="selectValue" v-if="showSelect==true"  @change="selectType" ref="input1" style="width:400px">
            <el-option v-for="(item,key,val,index) in SqlParams"  :key="index" :label="item.cn_name"  :value="item.en_name|capitalize"  :disabled="item.disabled">
              <span style="float: left">{{ item.cn_name}}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{item.en_name|capitalize}}</span>
            </el-option>
          </el-select>
          <el-input v-model="createTemplate.template_content"  maxlength="1000" show-word-limit type="textarea" 
            placeholder="请输入短信正文" :autosize="{ minRows: 5, maxRows: 10}" @keydown.native="listen($event)" id="inputDom">
          </el-input>
            <span type="info" :underline="false" style="font-size: 12px;color: #999;font-weight: 100;" >
              <i class="el-icon-view"></i>  输入@触发下拉框，您可选择下拉框中的变量添加到短信内容中 </span>
        </el-form-item>
        <el-form-item label="短信签名" prop="template_signature" label-width="80px">
          <el-input placeholder="请输入短信签名" v-model="createTemplate.template_signature"> </el-input>
            <span type="info" :underline="false" style="font-size: 12px;color: #999;font-weight: 100;">
           <i class="el-icon-info"></i> 请使用公司名简称、或网站名、App名作为短信签名，请控制在2-10个字符以内</span>
        </el-form-item>
      </el-form>
    </ui-modal>
  </div>
</template>
<script>
import {errorAlert,successAlert}from "../../../utils/alert"

export default {
  props: ['row'],
  filters:{
       capitalize:function(value){
         if(!value)return ''
         value=value.toString();
         switch(value){
                case "sum_order_count":
                  return "var(sum_order_count) ";
                  case "sum_order_pay":
                  return "var(sum_order_pay)";
                    case "last_order_pay":
                  return "var(last_order_pay)";
                   case "last_order_item_name":
                  return "var(last_order_item_name)";
                    case "days_payable_outstanding":
                  return "var(days_payable_outstanding)";
                   case "last_order_createtime":
                  return "var(last_order_createtime)";
                   case "last_order_createtime_days":
                  return "var(last_order_createtime_days)";
                    case "first_order_createtime":
                  return "var(first_order_createtime)";
                   case "first_order_pay":
                  return "var(first_order_pay)";
         }
       }
     },
  data() {
    const { row } =  this;
    const validateSignature=(rule, value, callback)=>{
      let reg=/【(\S*)】/;
      if(value === ""){
        callback(new Error ('请输入短信签名'));
      }else if (!reg.exec(value)){
        callback(new Error('签名必须使用【】包含!'));
      }
      callback();
    }
    return {
      showSelect: false,
      SqlParams:[],
      selectValue:[],
      valueCount:0,
        createTemplate: row ? { ...row,template_title:'',template_signature:''} : 
      {
        template_id:'',
        template_title:'',
        template_signature:'【】',
        template_content:''
      },

      modalProps: {
        title: row ? "编辑短信模版":"新建短信模版",
        okText:"提交审核",
        width: "45%",
        isEdit: !!row,
        onSubmit:async () => {
          await this.$refs.form.validate();
            if(row){
              this.update();
            }else{
              this.add();
            }
          },
      },

      createTemplate: row ?{...row}:{
        template_title: "", 
        template_content: "", 
        template_signature: "", 
      },

      rules:{
        template_content:[
           { required: true, message: '请输入短信正文', trigger: 'blur' },
        ],
        template_signature:[
            {  validator: validateSignature, trigger: "blur" }
        ]
      }
    };
  },
  created() {
      this.getSqlParams();
  },
  methods: {

    async getSqlParams(){
      const res = await this.$http.get("/periodic_task/sql_builder_params/get");
      this.SqlParams = res.data.data.params;
    },

    async update(){
      let params = {
            template_id:this.createTemplate.template_id,
            template_title:this.createTemplate.template_title,
            template_signature:this.createTemplate.template_signature,
            template_content:this.createTemplate.template_content
          }
        const res = await this.$http.post("/template/update",params); 
          if (res.data.code==0) {
            successAlert(res.data.msg)
          }else{
            errorAlert(res.data.msg)
          }
    },

    async add(){
        let params = {
              template_title:this.createTemplate.template_title,
              template_signature:this.createTemplate.template_signature,
              template_content:this.createTemplate.template_content
            }
        const res = await this.$http.post("/template/add", params);
          if (res.data.code==0) {
            successAlert(res.data.msg)
          }else{
            errorAlert(res.data.msg)
          }
        },

    listen(event){
        if(event.shiftKey && event.which===50){
          this.showSelect = true;
        }else{
          this.selectValue=[]
          this.showSelect = false;
        }
        },
        
    selectType(val){
        var inputDom = document.getElementById("inputDom");
        var startPos = inputDom.selectionStart;
        var endtPos = inputDom.selectionEnd;
        if(startPos === undefined && endtPos === undefined) return
        let txt = inputDom.value;
        let result = txt.substring(0,startPos) + val+ txt.substring(endtPos);
        this.createTemplate.template_content=result
      },

  },
};
</script>
<style lang="scss" scoped>
 .el-form-item__label{
    color: #1E90FF;
  }
   .el-form-item--small .el-form-item__error {
    margin-top: 5px;
  }
 .el-form-item__error {
    position: absolute !important;
    bottom: 10px;
    right: 10px;
    text-align: center;
    filter: alpha(Opacity=40);
    opacity: 1;
    border-radius: 2px;
    font-size: 12px;
    color: #1E90FF;
    line-height: 21px;
    z-index: 99;
  }
</style>
